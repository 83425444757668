@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

/* My CSS */

body, html, #root {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}

.content-container {
  /* background-color: rgba(228, 228, 228, 0.8); */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.foreground-container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  padding: 20px;
  margin-top: 0%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.centered-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  box-sizing: border-box;
}

.brand {
  max-width: 200px;
  height: auto;
}

/* Navbar styling */
.navbar {
  background-color: rgb(255, 255, 255); /* Tailwind's bg-gray-800 */
  color: black; /* Tailwind's text-white */
  padding: 16px 24px; /* Tailwind's px-4 (16px) and py-3 (12px) */
  display: flex; /* Tailwind's md:flex */
  align-items: center; /* Tailwind's md:items-center */
  justify-content: space-between; /* Tailwind's md:justify-between */
  border-radius: 50px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

/* Logo styling */
.logo-image {
  height: 40px; /* Adjust height as needed */
  width: auto;
}
